import type { Chain } from '@wagmi/core/chains'

export type FaucetAmount = '500' | '1M' | 'CLAIM'

export interface Network {
  id: string
  icon: string
  name: string
  RPC: string
  faucetURL?: string
  notify: string
  chain: Chain
  thresholds: {
    nativeToken: number
    czkcToken: number
  }
}

export interface Social {
  icon?: string
  link?: string
  name: string
  value: SocialType
}

export enum UseMethodType {
  COMMON = 'common',
  DEFI = 'defi',
}

export enum SocialType {
  FACEBOOK = 'facebook',
  X = 'x',
  LINKEDIN = 'linkedin',
  NONE = 'none',
}

export interface SendEmailBody {
  amount: FaucetAmount
  chainId: number
  userAgent: string
  origin: string
  email: Email
}

export interface Email {
  head: EmailHead
  body: EmailBody
}

export interface EmailHead {
  recipient: string
  subject: string
}

export interface EmailBody {
  message: string
}

export interface SendStatus {
  type: FaucetAmount
  link: string
}

export interface MailtoInfo {
  ethAddress: `0x${string}`
  chainId: number
  envelope: MailtoInfoEnvelope
  messageId: string
  ipAddress: string
  userAgent: string
}

export interface MailtoInfoEnvelope {
  from: string
  to: string
}
