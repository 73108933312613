import { SocialType, type Social } from '~/types'

export default [
  {
    icon: '/images/logo/Facebook.svg',
    link: 'https://czkc.io/',
    name: 'Facebook',
    value: SocialType.FACEBOOK,
  },
  {
    icon: '/images/logo/X.svg',
    link: 'https://x.com/CZKC_cz/',
    name: 'X',
    value: SocialType.X,
  },
  {
    icon: '/images/logo/Linkedin.svg',
    link: 'https://czkc.io/',
    name: 'LinkedIn',
    value: SocialType.LINKEDIN,
  },
] as Social[]
