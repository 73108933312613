import { default as _91uuid_93BA8ccFdRM5Meta } from "/vercel/path0/pages/claim/[uuid].vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
export default [
  {
    name: "claim-uuid",
    path: "/claim/:uuid()",
    component: () => import("/vercel/path0/pages/claim/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  }
]