import revive_payload_client_hayNKJeSuH from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.8_@upstash+redis_l3pkukjtwcz7l5a7du3mly465u/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_bfTYhbGGP2 from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.8_@upstash+redis_l3pkukjtwcz7l5a7du3mly465u/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_UlVmHDPs9U from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.8_@upstash+redis_l3pkukjtwcz7l5a7du3mly465u/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import sentry_client_SFynMFEnem from "/vercel/path0/node_modules/.pnpm/@develit-io+nuxt-sentry@0.4.2_encoding@0.1.13_magicast@0.3.4_rollup@4.18.0_vue@3.4.35_typescript@5.5.4_/node_modules/@develit-io/nuxt-sentry/dist/runtime/sentry.client.mjs";
import navigation_repaint_client_kLnSUv0QJv from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.8_@upstash+redis_l3pkukjtwcz7l5a7du3mly465u/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LnZ8RWZ3HC from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.8_@upstash+redis_l3pkukjtwcz7l5a7du3mly465u/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_LlBkxLWOvS from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.8_@upstash+redis_l3pkukjtwcz7l5a7du3mly465u/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_DawCB3XWsF from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.3_magicast@0.3.4_rollup@4.18.0_typescript@5.5.4_vue@3.4.35_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_0fmhKFN0MD from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.8_@upstash+redis_l3pkukjtwcz7l5a7du3mly465u/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_yyzJAYW5Tv from "/vercel/path0/node_modules/.pnpm/@nuxtjs+plausible@1.0.2_magicast@0.3.4_rollup@4.18.0/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import plugin_6Ph1iQzNHk from "/vercel/path0/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import switch_locale_path_ssr_UNbxlRbTLR from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_magicast@0.3.4_rollup@4.18.0_vue@3.4.35_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_VlrPHmAoNd from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_magicast@0.3.4_rollup@4.18.0_vue@3.4.35_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_JOp3LWoYa0 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.4.2_magicast@0.3.4_rollup@4.18.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import unocss_MzCDxu9LMj from "/vercel/path0/.nuxt/unocss.mjs";
import plugin_auto_pageviews_client_kHOUA8aGT9 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+plausible@1.0.2_magicast@0.3.4_rollup@4.18.0/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
export default [
  revive_payload_client_hayNKJeSuH,
  unhead_bfTYhbGGP2,
  router_UlVmHDPs9U,
  sentry_client_SFynMFEnem,
  navigation_repaint_client_kLnSUv0QJv,
  check_outdated_build_client_LnZ8RWZ3HC,
  chunk_reload_client_LlBkxLWOvS,
  plugin_vue3_DawCB3XWsF,
  components_plugin_KR1HBZs4kY,
  prefetch_client_0fmhKFN0MD,
  plugin_client_yyzJAYW5Tv,
  plugin_6Ph1iQzNHk,
  switch_locale_path_ssr_UNbxlRbTLR,
  i18n_VlrPHmAoNd,
  plugin_client_JOp3LWoYa0,
  unocss_MzCDxu9LMj,
  plugin_auto_pageviews_client_kHOUA8aGT9
]